<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>Why Come to an Amchara Retreat?</h1>
      </div>
    </div>

    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <!-- Kickstart Your Helth / Who Our Retreats Are For Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto text-center">
            <div class="green-header-2-text">
              Amchara provides you a personalised health experience that is
              tailored to your needs and your goals
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <p>
              We provide you a supportive and nurturing environment to enable to
              change your health outcomes. Our experienced health team are
              driven to help you change your health outcomes and achieve your
              goals.
            </p>
            <p>
              When you attend Amchara you will have one thing in common with
              every other one of our clients, you want something to Change. Our
              mission is to help you ‘Change For Good’ with a personalised
              approach.
            </p>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- We Can Help You Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              We can help you with...
            </div>
            <div class="row">
              <div class="col-md-7 mb-5 my-md-auto">
                <div class="row">
                  <div class="col-md-6">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-2 mb-md-0">Changing Habits</li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Weight Management
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">Overall Fitness</li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Increased Longevity
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Creating a Supportive Environment
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">Diabetes</li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="home-bullet-points-right">
                      <li class="p-list-item mb-2 mb-md-0">Arthritis</li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Stress &amp; Lifestyle Management
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">Gut Problems</li>
                      <li class="p-list-item mb-2 mb-md-0">Hormones</li>
                      <li class="p-list-item mb-2 mb-md-0">Fertility</li>
                    </ul>
                  </div>
                  <div class="col mt-5 mt-md-3 text-center">
                    <router-link
                      to="/enquiries"
                      class="btn btn-orange-gradient enquire-now-button"
                      >ENQUIRE NOW</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-5 mb-5 my-md-auto">
                <img
                  width="100%"
                  src="@/assets/images/pages/why-come/help-you.jpg"
                  alt="Help you image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />
      <locations></locations>

      <trip-advisor></trip-advisor>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Personalised Health Retreat Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <div class="green-header-2-text">
              Your personalised health retreat
            </div>
            <p class>
              If you want to come to Amchara it is because you want to invest in
              your health. Our health retreat programmes are designed to help
              you detoxify both physically and mentally. You are immersed in a
              positive and supportive environment; increasing the opportunity
              for your body and mind to start the change process, move toward a
              greater sense of well-being and heal. Our retreat locations are
              set within stunning locations, allowing you to escape the world
              and focus entirely on you. Our UK retreat is set within a
              beautiful country manor house in Somerset and our Malta retreat
              can be found within the stunning island of Gozo - Europe’s best
              kept secret.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-3 mb-3">&nbsp;</div>
      <personalised></personalised>
      <div class="mt-3 mb-3">&nbsp;</div>
      <featured-mags></featured-mags>

      <div class="container my-5">
        <div class="row">
          <div class="col-md-12 mx-auto text-center">
            <router-link
              to="/functional-health-in-action"
              class="btn btn-green-gradient green-link-button"
            >
              Your personalised
              <br class="d-block d-md-none" />retreat experience
            </router-link>
          </div>
        </div>
      </div>

      <hr width="80%" />
      <testimonials></testimonials>

      <div class="mt-3 mb-3">&nbsp;</div>
      <!--<hr width="80%" />
      <instagram></instagram>-->
    </div>
    <!-- body-content End -->

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Personalised from "./page-partials/Personalised";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisor from "./page-partials/TripAdvisor";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [require("@/assets/images/pages/why-come/header-image.jpg")],
    };
  },
  components: {
    FeaturedIn,
    Personalised,
    FeaturedMags,
    Locations,
    TripAdvisor,
    Testimonials,
    Instagram,
    NewsletterCta,
  },
};
</script>

<style>
</style>